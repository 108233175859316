<template>
  <b-modal ref="load-crm-counterpart-warning-202102031020"
           id="load-crm-counterpart-warning-202102031020"
           v-model="showModal"
           :title="staticText.warning"
           @cancel="$emit('cancel')"
           @ok="$emit('ok')"
  >
    <b-alert variant="warning"
             show
    >
      <p>
        {{ staticText.warningMessage1 }}
      </p>
      <p>
        {{ staticText.warningMessage2 }}
      </p>
    </b-alert>
  </b-modal>
</template>

<script>

export default {
  name: "LoadCrmDataWarningModal",
  data () {
    return {
      staticTextDefault: {
        warning: "Warning",
        warningMessage1: "Loading counterpart data will override the current CRM fields",
        warningMessage2: "Are you sure you want to override the current CRM fields?"
      },
      showModal: false
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    }
  },
  methods: {
    openModal () {
      this.showModal = true
    },
    closeModal () {
      this.showModal = false
    }
  }
}
</script>

<style>

</style>
