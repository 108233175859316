<template>
  <b-modal
    id="counterpartSelectionModal"
    v-model="showModal"
    body-class="p-0"
    header-class="p-0"
    dialog-class="p-2"
    hide-footer
    centered
    @hidden="$emit('counterparts-modal-closed')"
  >
    <counterpart-selector
      id="cpselector"
      ref="cpselector"
      class="container-fluid max-h-500"
      :value="counterparts"
      :hide-load-crm-data-button="hideLoadCrmDataButton"
      @download-crm-data="$emit('download-crm-data')"
      @input="value => $emit('set-counterparts', value)"
    ></counterpart-selector>
    <template v-slot:modal-header>
      <div class="modal-header">
        <div class="d-flex align-items-center">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'team-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g opacity=".8" fill="#2B2727"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.795 8.373a4.11 4.11 0 001.569.31c2.216 0 4.019-1.746 4.019-3.892S13.58.9 11.364.9a4.11 4.11 0 00-1.569.309c1.44.592 2.45 1.975 2.45 3.582 0 1.607-1.01 2.99-2.45 3.582zM12.612 9.575a5.155 5.155 0 001.97-.972c1.519 1.004 2.518 2.694 2.518 4.605v1.965c0 1.062-.893 1.927-1.99 1.927h-2.707l-.006-.045c.894-.189 1.565-.96 1.565-1.882v-1.965c0-1.566-.378-2.716-1.35-3.633z"/><path d="M6.658 8.682c2.216 0 4.019-1.745 4.019-3.891S8.874.9 6.657.9C4.443.9 2.64 2.646 2.64 4.791c0 2.146 1.803 3.891 4.019 3.891z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M6.658 9.73a5.18 5.18 0 01-3.24-1.128C1.899 9.607.9 11.297.9 13.208v1.964c0 1.063.893 1.927 1.99 1.927H10.404c1.097 0 1.99-.864 1.99-1.927v-1.964c0-1.911-.999-3.6-2.518-4.606A5.18 5.18 0 016.658 9.73z"/></g></svg>
          <span class="ml-2 subTitle">{{ staticText.selectCounterpartsTitle }}</span>
        </div>
        <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'ml-2 cursor-pointer'" @click="closeModal" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7 .6C3.48.6.6 3.48.6 7c0 3.52 2.88 6.4 6.4 6.4 3.52 0 6.4-2.88 6.4-6.4C13.4 3.48 10.52.6 7 .6zm3.2 8.704l-.896.896L7 7.896 4.696 10.2 3.8 9.304 6.104 7 3.8 4.696l.896-.896L7 6.104 9.304 3.8l.896.896L7.896 7 10.2 9.304z" fill="#AAABBA"/></svg>
      </div>
    </template>
  </b-modal>
</template>

<script>
import CounterpartSelector from "@/apps/call/CallViewCounterpartComponents/CounterpartSelector"

export default {
  name: "CounterpartSelectionModal",
  data () {
    return {
      showModal: false,
      staticTextDefault: {
        selectCounterpartsTitle: "Select your Counterpart(s)"
      }
    }
  },
  components: { CounterpartSelector },
  props: {
    counterparts: {
      type: Array,
      default: () => []
    },
    hideLoadCrmDataButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    openModal () {
      this.showModal = true
    },
    closeModal () {
      this.showModal = false
    }
  }
}
</script>

<style lang="scss" scoped>

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 16px 18px 20px;
  width: 100%;
  border: none;
  .team-icon {
    g {
      opacity: 1;
      path {
        fill: $black80;
      }
    }
  }
  .subTitle {
    font-weight: 700;
    color: $black80;
  }
}

.max-h-500 {
  max-height: 500px;
}

</style>
