<template>
  <div class="content-wrapper">
    <div id="counterpart-content-container"
         class="d-flex p-0 container-fluid"
    >
      <div
        v-if="!canUseCRM"
        id="no-crm-connected-202101181922"
        class="container-fluid p-3 no-counterpart"
      >
        <div class="row justify-content-center">
          {{ staticText.listEmptyText }}
        </div>
        <div class="row justify-content-center">
          <router-link to="/users/integrations"
                       target="_blank">
            <img src="../../../../public/img/icons/jump_external.svg"
                 class="mb-1"
                 width="16"/>
            {{ staticText.connectCrmLinkText }}
          </router-link>
        </div>
      </div>
      <div
        v-else
        class="pl-3 pr-2 py-3 d-flex flex-column w-100"
      >
        <!-- search input and error modal -->
        <div class="d-flex align-items-center h-64 pr-2">
          <!-- search input section   -->
          <b-form-input
            v-model="queryTerm"
            :placeholder="staticText.typeToSearchPlaceholder"
            :autofocus="true"
            size="5"
            class="pr-5"
            @input="searchCounterparts(queryTerm)"
          ></b-form-input>
          <!-- search icon  -->
          <span class="search-icon"
                @click="searchCounterparts(queryTerm)"
          >
            <progress-indicator
              v-if="loading"
              :showLoadingLabel="false"
              small
              :tooltip="true"
            ></progress-indicator>
            <img v-else src="../../../../public/img/icons/search-icon.svg"/>
          </span>

          <!-- error modal  -->
          <span v-if="errors"
                class="ml-4"
          >
            <error-modal
              v-model="errors"
              :errors="[errors]"
              error-header-text=""
              @Done="searchCounterparts(queryTerm)"
            >
              <p>
                {{ staticText.CRMErrorText1 }}
                <router-link to="/users/integrations"
                             target="_blank">
                  {{ staticText.CRMErrorText2 }}
                  <img src="../../../../public/img/icons/jump_external.svg"
                       class="mb-1"
                       width="16"/>
                </router-link>
                {{ staticText.CRMErrorText3 }}.
              </p>
            </error-modal>
          </span>
        </div>

        <!-- counterparts table   -->
        <div id="counterparts-list" class="container-counterpart nice-scrollbar noscroll-x">
          <div>
            <!--  no table data  -->
            <div
              id="no-table-data"
              v-if="!loading"
              class="pt-3 text-center"
            >
              <span
                v-if="!queryTerm || queryTerm.length < 3"
              >
                {{ staticText.threeCharactersSearch }}
              </span>
              <span v-else>
                <span v-if="!sortedCounterpartOptions.length">{{ staticText.noResultsText }}</span>
              </span>
            </div>
            <div v-else-if="loading && !sortedCounterpartOptions.length">
              <progress-indicator></progress-indicator>
            </div>
            <div id="add-new-counterpart"
                 class="py-3 text-center"
                 v-if="canUseBaoCrm && !!queryTerm"
            >
              <a id="addNewCounterpart-20219271330"
                 href='#'
                 @click="getSelectedCounterpart({crm_service: 'bao', name: queryTerm}, true,)"
              >
                + {{ staticText.addNewCounterpartText }}
              </a>
            </div>
            <!--  selected counterparts  -->
            <div :id="'selected-counterpart-' + index"
                 v-for="(item, index) in selectedCounterparts"
                 :key="'selected-counterpart-' + index"
                 class="single-counterpart selected"
            >
              <div class="d-flex align-items-center w-100">
                <div
                  class="toggle-btn"
                  role="button"
                  @click="toggleSelection(item)"
                >
                  <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.6.594a.644.644 0 010 .934l-5.486 5.28a.706.706 0 01-.97 0L.4 4.167a.643.643 0 010-.935.706.706 0 01.97 0L3.63 5.405l5-4.811a.706.706 0 01.971 0H9.6z" fill="#5D514B" fill-opacity=".4"/></svg>
                </div>
                <div class="counterpart-cell">
                  <counterpart-display-cell
                    :name="getCounterpartName(item)"
                    :type="item.type"
                    :crm_service="item.crm_service"
                    :direct_link="item.direct_link"
                  ></counterpart-display-cell>
                </div>
              </div>
              <b-btn v-if="canShowLoadButton(item)"
                     class="p-0 bg-transparent"
                     @click="loadCrmData(item, index)"
              >
                <i class="fas fa-download"></i>
              </b-btn>
            </div>
            <!--  not selected counterparts  -->
            <div :id="'not-selected-counterparts-' + index"
                 v-for="(item, index) in sortedCounterpartOptions"
                 :key="'not-selected-counterparts-' + index"
                 class="w-100"
                 @click="toggleSelection(item)"
            >
              <div
                v-if="!isSelected(item.id)"
                class="single-counterpart unselected"
              >
                <div class="toggle-btn"></div>
                <div class="counterpart-cell">
                  <counterpart-display-cell
                    :name="getCounterpartName(item)"
                    :type="item.type"
                    :crm_service="item.crm_service"
                    class="counterpart-cell"
                  ></counterpart-display-cell>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- Modal to show warning on loading counterpart data from CRM-->
      <load-crm-data-warning-modal
        ref="loadCrmDataWarningModal"
        @cancel="handleCancel"
        @ok="getSelectedCounterpart(counterpartToLoad, false, true)"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios"
import appInfo from "../../counterpart"
import CounterpartDisplayCell from "../CounterpartDisplayCell"
import ErrorModal from "../../base/ErrorModal"
import { mapGetters, mapActions, mapMutations } from "vuex"
import { currentCallStore } from "@/store/services/callStore"
import ProgressIndicator from "@/apps/base/ProgressIndicator"
import LoadCrmDataWarningModal from "./LoadCrmDataWarningModal"
import { checkIfLoadedCrmFieldsExist } from "../utils"

export default {
  name: "CounterpartSelector",
  components: {
    ErrorModal,
    CounterpartDisplayCell,
    ProgressIndicator,
    LoadCrmDataWarningModal
  },
  props: {
    value: { // counterparts
      type: Array,
      required: true
    },
    crmData: {
      type: Object,
      default: null,
      required: false
    },
    hideLoadCrmDataButton: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      appInfo,
      axios,
      selectedCounterparts: [],
      staticTextDefault: {
        noResultsText: "No counterparts found.",
        addNewCounterpartText: "Add new counterpart",
        CRMErrorText1: "Unable to load data from external crm.Please check the",
        CRMErrorText2: "integrations page",
        CRMErrorText3: "for more information",
        listEmptyText: "No CRM connected.",
        connectCrmLinkText: "Connect to CRM.",
        typeToSearchPlaceholder: "Type to search",
        threeCharactersSearch: "Please type at least 3 characters to search"
      },
      localErrors: null, // todo: move all errors to store
      counterpartToLoad: null,
      queryTerm: null
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      canUseBaoCrm: "auth/canUseBaoCrm",
      canStoreCRMData: "auth/canStoreCRMData",
      canUseCRM: "auth/canUseCRM",
      CRMSupportsLoadingOfData: "counterpartStore/CRMSupportsLoadingOfData",
      loading: "counterpartStore/getLoading",
      getErrors: "counterpartStore/getErrors",
      counterpartOptions: "counterpartStore/getCounterpartOptions",
      counterpartsWithLoadedData: "counterpartStore/getCounterpartsWithLoadedData"
    }),
    errors () {
      return this.localErrors || this.getErrors
    },
    loadedCrmFields () {
      return currentCallStore.loadedCrmFields
    },
    sortedCounterpartOptions () {
      const array = this.counterpartOptions.map((counterpart, index) => {
        return { ...counterpart, originalIndex: index }
      })
      return array.sort((a, b) => {
        try {
          return a.name.localeCompare(b.name)
        } catch (err) {
          console.error(err)
        }
        return 0
      })
    },
    nonSelectedCounterparts () {
      if (!this.selectedCounterparts.length) return this.sortedCounterpartOptions
      else if (!this.sortedCounterpartOptions.length) return []
      return this.sortedCounterpartOptions.filter(counterpart => {
        return !this.selectedCounterparts.find(item => item.id === counterpart.id)
      })
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  watch: {
    value (newVal) {
      if (JSON.stringify(this.selectedCounterparts) !== JSON.stringify(newVal)) {
        this.selectedCounterparts = newVal
      }
    }
  },
  mounted () {
    this.selectedCounterparts = this.value
    this.setCounterpartOptions([])
  },
  methods: {
    ...mapMutations({
      setCounterpartOptions: "counterpartStore/setCounterpartOptions"
    }),
    ...mapActions({
      asyncSearch: "counterpartStore/asyncSearch",
      updateCounterpartsWithLoadedData: "counterpartStore/updateCounterpartsWithLoadedData"
    }),
    canShowLoadButton (counterpart) {
      return !this.hideLoadCrmDataButton && this.canStoreCRMData && this.CRMSupportsLoadingOfData(counterpart)
    },
    searchCounterparts (queryTerm) {
      this.asyncSearch(queryTerm)
    },
    saveNewCounterpart (cpData) {
      if (cpData.id) return Promise.resolve(cpData)
      return new Promise((resolve, reject) => {
        this.axios.post("/api/counterpartsnew", cpData).then(response => {
          const index = cpData.originalIndex
          const clonedArray = [...this.counterpartOptions]
          clonedArray.splice(index, 1, response.data)
          this.setCounterpartOptions(clonedArray)

          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    isEmpty (obj) {
      for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
          return false
        }
      }
      return true
    },
    getSelectedCounterpart (selectedOption, isTag = false, isLoaded = false) {
      // todo move this method to store
      return new Promise((resolve, reject) => {
        const url = "/api/counterpartsnew/get_counterpart?service=" + selectedOption.crm_service
        return this.axios.post(url, selectedOption).then((response) => {
          const newCounterpart = { ...response.data, crmDataLoaded: isLoaded }

          this.updateCrmData(newCounterpart)
          if (isTag || isLoaded) {
            if (isTag) this.selectedCounterparts.push(newCounterpart)
            else this.selectedCounterparts.splice(selectedOption.index, 1, newCounterpart)
            this.$emit("input", this.selectedCounterparts)
          }
          resolve(this.selectedCounterparts)
        }).catch(error => {
          if (!this.localErrors) this.localErrors = {}
          this.localErrors.Counterpart = error.response.data.error
          reject(error)
        })
      })
    },
    async updateCrmData (newCounterpart) {
      const counterpartCRM = newCounterpart.crm_service
      const counterpartType = newCounterpart.type
      const counterpartsWithLoadedData = {}
      counterpartsWithLoadedData[`${counterpartCRM}-${counterpartType}`] = newCounterpart
      await this.updateCounterpartsWithLoadedData(counterpartsWithLoadedData)
      this.$emit("download-crm-data")
    },
    getCounterpartName (option) {
      return option.name ? option.name : option.crm_id
    },
    async loadCrmData (counterpart, index) {
      counterpart = { ...counterpart, index }
      const loadedCrmFieldsExist = await checkIfLoadedCrmFieldsExist([counterpart])
      if (loadedCrmFieldsExist) {
        this.counterpartToLoad = counterpart
        this.$refs.loadCrmDataWarningModal.openModal()
      } else this.getSelectedCounterpart(counterpart, false, true)
    },
    handleCancel () {
      this.$refs.loadCrmDataWarningModal.closeModal()
      this.counterpartToLoad = null
    },
    async toggleSelection (counterpart) {
      if (this.isSelected(counterpart.id)) {
        this.selectedCounterparts = this.selectedCounterparts.filter(item => item.id !== counterpart.id)
      } else {
        if (counterpart.id) this.selectedCounterparts.push(counterpart)
        else {
          const newCounterpart = await this.saveNewCounterpart(counterpart)
          this.selectedCounterparts.push(newCounterpart)
        }
      }
      this.$emit("input", this.selectedCounterparts)
    },
    isSelected (counterpartId) {
      if (!counterpartId) return false
      return this.selectedCounterparts.some(item => item.id === counterpartId)
    }
  }
}
</script>

<style scoped lang="scss">

.h-64 {
  height: 64px;
}

.content-wrapper {
  display: flex;
  justify-content: center;
  min-width: 200px;
  height: 100%;
  padding: 0;
}

.container-counterpart {
  max-height: 250px;
  padding: 0 8px 0 0;
  overflow-y: auto;
  @include media-breakpoint-up(md) {
    max-height: calc(100% - 64px);
  }
}

.counterparts-table {
  background: inherit;
  border-radius: unset;
}

.cursor-pointer {
  cursor: pointer;
}

.search-icon {
  color: $slate40;
  position: relative;
  margin-left: -40px;
  cursor: pointer;
}

.toggle-btn {
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF !important;
  border: 1px solid $slate40;
  border-radius: 5px;
  width: 20px;
  height: 20px;
  svg {
    path {
      fill: $slate;
      fill-opacity: 1;
    }
  }
}

.bg-light-orange {
  background-color: $orange06;
}

.single-counterpart {
  padding: 16px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $black06;
  .counterpart-cell {
    width: calc(100% - 32px);
  }
  &.selected {
    justify-content: space-between;
  }
  &.unselected {
    cursor: pointer;
  }
  &:last-child {
    border: none;
  }
}

.no-counterpart {
  color: $slate60;
  a {
    color: $slate60 !important;
    text-decoration: underline;
  }
}

</style>
